.preserve-whitespace .ql-editor {
  white-space: pre-wrap !important;
}

.preserve-whitespace .ql-editor p {
  white-space: pre-wrap !important;
}

.preserve-whitespace .ql-editor br {
  display: block !important;
  content: '' !important;
  margin-top: 0.5em !important;
} 